import React, { Component } from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../../components/layout"
// import BackgroundImage from 'gatsby-background-image'
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"

import s from "../aboutStyles.module.scss"

// import ShowWindowDimensions from "../components/ShowWindowDimensions"

class ToKillAKing extends Component {
   constructor(props) {
      super(props)

      this.state = { narrowScreen: false }
   }

   componentDidMount() {
      this.updateDimensions()
      window.addEventListener("resize", this.updateDimensions)
   }

   componentDidUpdate(prevProps, prevState) {}

   componentWillUnmount() {
      window.removeEventListener("resize", this.updateDimensions)
   }

   updateDimensions = () => {
      window.innerWidth < 540
         ? this.setState({ narrowScreen: true })
         : this.setState({ narrowScreen: false })
   }

   render() {
      const animationStatus = {
         transitionStatus: this.props.transitionStatus,
         entry: this.props.entry,
         exit: this.props.exit,
      }

      const footerContent = {
         C2As: [
            {
               text: "Check out some of my recent work here.",
               link: "about-me",
            },
            { text: "Here are some other projects", link: "projects" },
         ],
         styles: {
            backgroundColor: "transparent",
         },
      }

      return (
         <ParallaxProvider>
            <div className={`mainContainer ${s.aboutMePage}`}>
               <Layout
                  title="Other Projects"
                  superTitle="Mr BJ Jackson / Projects"
                  pageStyles={s}
                  animationStatus={animationStatus}
                  footerContent={footerContent}
                  isProjectPage={true}
               >
                  <div
                     className="articleSection row4"
                     style={{ marginTop: "100px" }}
                  >
                     <div className="left">
                        <Parallax
                           y={["-50px", "50px"]}
                           disabled={this.state.narrowScreen}
                        >
                           <Image
                              fluid={this.props.data.file.childImageSharp.fluid}
                           />
                        </Parallax>
                     </div>
                     <div className="right">
                        <ul className="contactDetails">
                           <li>
                              <a href="mailto:ben@mrbjjackson.com">
                                 <span className={`fa fa-envelope`}> </span>{" "}
                                 ben@mrbjjackson.com
                              </a>
                           </li>
                           <li>
                              <a
                                 href="https://www.instagram.com/mrbjjackson"
                                 title="Mr BJ Jackson on Instagram"
                              >
                                 <span className={`fa fa-instagram`}> </span>{" "}
                                 @mrbjjackson
                              </a>
                           </li>
                           <li>
                              <a href="tel:07737042211">
                                 <span className={`fa fa-phone`}> </span>{" "}
                                 07737042211
                              </a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </Layout>
            </div>
         </ParallaxProvider>
      )
   }
}

export default ToKillAKing

export const data = graphql`
   query {
      file(relativePath: { eq: "Me by Taffel.jpg" }) {
         childImageSharp {
            fluid {
               base64
               aspectRatio
               originalImg
               originalName
               presentationHeight
               presentationWidth
               sizes
               src
               srcSet
               srcSetWebp
               srcWebp
               tracedSVG
            }
         }
      }
   }
`
